import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  name: 'FastTime',
  props: {
    start: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      value2: this.start
    };
  },
  watch: {},
  mounted: function mounted() {},
  methods: {
    FinalFollowUpEs: function FinalFollowUpEs(val) {
      var time = [];
      time.unshift(val[0].toString().substring(0, 10));
      time.push(val[1].toString().substring(0, 10));
      this.$emit('FinalPicker', time);
    }
  }
};